var render, staticRenderFns
import script from "./associated_position_two.vue?vue&type=script&lang=js&"
export * from "./associated_position_two.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cd24de4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/web-page/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7cd24de4')) {
      api.createRecord('7cd24de4', component.options)
    } else {
      api.reload('7cd24de4', component.options)
    }
    
  }
}
component.options.__file = "found/views/mdm/views/role_manage/form/associated_position/associated_position_two.vue"
export default component.exports